.TrainerInfo {
  width: 100vw;
  height: fit-content;
  display: flex;
  justify-content: center;
  font-size: 16px;
  line-height: 24px;

  &_page {
    width: 1200px;
    margin-top: 20px;
    margin-bottom: 80px;
    height: fit-content;

    &_topic {
      width: auto;
      height: 32px;
      margin-bottom: 20px;
      display: flex;    
      align-items: center;

      &_circle {
        position: relative;
        width: 32px;
        height: 32px;
        background: #DADDE1;
        border-radius: 50%;

        &_arrow {
          position: absolute;
          left: 11.2px;
          top: 8px;
          width: 8.37px;
          height: 14.4px;
          background-image: url(../../icons/Arrow.svg);
        }
      }

      &_text {
        margin-left: 8px;
        font-weight: 600;
      }
    }

    &_container {
      display: flex;
      justify-content: space-between;

      &_side {
        width: auto;
        border: 1px solid #ecec05;

        &_photo {
          width: 560px;
          height: 560px;
          border-radius: 20px;
          background-color: rgba(42, 6, 243, 0.466);

          & img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center top;
            border-radius: 20px;
          }
        }
      }

      &_main {
        width: 600px;

        &_name {
          font-size: 34px;
          line-height: 36px;
        }

        &_info {
          margin-top: 20px;
        }

        &_buttons {
          margin-top: 32px;
          margin-bottom: 40px;
          display: flex;
          gap: 8px;

          &_record {
            box-sizing: border-box;
            width: 220px;
            height: 52px;
            border-radius: 12px;
            padding: 14px 20px;
            background: #00BDC7;
            font-size: 20px;
            text-align: center;
            color: #FFFFFF;

          }

          &_recall {
            box-sizing: border-box;
            width: 220px;
            height: 52px;
            border-radius: 12px;
            padding: 14px 20px;
            background: #DADDE1;
            font-size: 20px;
            text-align: center;
            color: #141E32;

          }
        }

        &_box {
          font-family: 'Montserrat';
          display: flex;
          flex-direction: column;
          gap: 20px;

          &_item {
            font-family: 'Montserrat';
            margin-bottom: 30px;

            &_header {
              letter-spacing: 0.15px;
              font-weight: 600;
              width: 343px;
              margin-bottom: 8px;
            }

            &_text {
              letter-spacing: 0.5px;
              font-weight: 400;
              width: auto;
              //min-height: 24px;
            }
          }
        }
      }
    }
  }
}