.Filter {
  grid-area: filterTr;
  //width: 290px;
  //height: 248px;
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &_item {
    width: 290px;
    height: 56px;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #E6E8EB;
    border-radius: 8px;
    font-family: 'Montserrat';
    font-weight: 600;
    cursor: pointer;

    &_topic {
      width: 234px;
      height: 16px;
      margin-top: 8px;
      margin-left: 12px;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.4px;
      opacity: 0.5;
    }

    &_text {
      width: 234px;
      height: 20px;
      margin-top: 4px;
      margin-left: 12px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
    }

    &_triangle {
      position: absolute;
      width: 16px;
      height: 16px;
      right: 17.43px;
      top: 20px;
      background-image: url(../../icons/triangle.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

@media all and (max-width: 1024px) {
  .Filter {
    flex-direction: row;
    flex-wrap: wrap;
    width: 640px;

    &_item {
      width: 316px;
    }
  }
}

@media all and (max-width: 719px) {
  .Filter {
    flex-direction: column;
    width: 296px;
    margin-left: 12px;

    &_item {
      width: 296px;
    }
  }
}