.Filter-module {
  position: absolute;
  z-index: 4;
  width: 292px;
  max-height: 260px;
  top: 61px;
  background: #FFFFFF;
  box-shadow: 0px 10px 40px rgba(5, 25, 50, 0.2);
  border-radius: 8px;
  overflow: scroll;
  cursor: default;

  font-family: 'Montserrat';
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;

  &_list {
    width: 260px;
    margin: 12px 20px 12px 12px;

    &_item {
      box-sizing: border-box;
      width: 260px;
      height: 36px;
      padding: 8px 32px 8px 8px;
      cursor: pointer;
    }

    &_item:not(:last-child) {
      margin-bottom: 4px;
    }

    &_item:hover {
      background: #F4F4F4;
      border-radius: 4px;
    }
  }
}

.choicen {
  background: #F4F4F4;
  border-radius: 4px;
}