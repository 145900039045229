.Trainers {
  width: 100vw;
  height: fit-content;
  display: flex;
  justify-content: center;

  &_page {
    width: 1200px;
    margin-top: 40px;
    margin-bottom: 100px;
    height: fit-content;

    &_topic {
      width: auto;
      //height: 96px;
      height: auto;

      &_head {
        width: auto;
        height: 36px;
        font-size: 34px;
        line-height: 36px;
      }

      &_text {
        width: 600px;
        height: 48px;
        margin-top: 12px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
    }

    &_container {
      margin-top: 40px;
      display: grid;
      gap: 42px;
      align-items: flex-start;
      grid-template-areas:
        'filterTr main'
        'join main'
        'none main';
      
      &_btns {
        grid-area: filterTr;

        &_open, &_open_gray, &_clear {
          box-sizing: border-box;
          height: 40px;
          position: relative;
          padding: 0;
          border-radius: 8px;
          cursor: pointer;
  
          & span {
            font-weight: 600;
            font-family: 'Nunito Sans';
            color: #141E32;
            text-align: center;
          }
        }
  
        &_open {
          background: #FFFFFF;
          border: 1px solid #00BDC7;
          width: 120px;
  
          & span {
            line-height: 24px;
            font-size: 16px;
          }
        }
  
        &_open_gray {
          background: #DADDE1;
          border: none;
          width: 144px;
  
          & span {
            line-height: 24px;
            font-size: 16px;
          }
        }
  
        &_clear {
          width: 124px;
          margin-left: 8px;
          background: #FFFFFF;
          border: 1px solid rgba(20, 30, 50, 0.1);
          box-shadow: 0px 16px 32px rgba(17, 16, 52, 0.04);
          border-radius: 10px;
  
          & span {
            position: relative;
            line-height: 16px;
            font-size: 12px;
            left: 15px;
          }

          & span::before {
            position: absolute;
            display: block;
            left: -29px;
            content: '';
            width: 18px;
            height: 18px;
            background-image: url(../../icons/Clear.svg);
          }
        }
      }    

      &_main {
        grid-area: main;
        width: 868px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        height: auto;
      }

      &_join {
        grid-area: join;
        width: 290px;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        border-radius: 20px;

        &_img {
          background-image: url(../../images/advertise.jpg);
          background-repeat: no-repeat;
          width: 290px;
          height: 400px;
          z-index: 1;
          position: absolute;
          border-radius: 20px;
        }
        
        &_linear {
          width: 290px;
          height: 400px;
          background: linear-gradient(180.3deg, rgba(108, 174, 226, 0) 25.57%, #3683E6 77.05%);
          z-index: 2;
          position: absolute;
          border-radius: 20px;
        }

        &_box {
          width: 250px;
          z-index: 3;
          position: relative;

          &_topic {
            height: 72px;
            margin-bottom: 8px;
            font-size: 34px;
            line-height: 36px;
            color: #FFFFFF;
          }

          &_text {
            height: 40px;
            margin-bottom: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #FFFFFF;
          }

          &_button {
            height: 52px;
            width: 250px;
            border: none;
            padding: 0;
            margin-bottom: 20px;
            background: #00BDC7;
            border-radius: 12px;
            cursor: pointer;

            & span {
              font-size: 20px;
              line-height: 24px;
              text-align: center;
              font-family: 'Nunito Sans';
              color: #FFFFFF;
            }
          }
        }
      }
    }
  }
}

@media all and (max-width: 1279px) {
  .Trainers_page {
    width: 945px;

    &_container_main {
      width: 655px;  
    }
  }
}

@media all and (max-width: 1024px) {
  .Trainers_page {
    width: 640px;

    &_container {
      margin-top: 20px;
      grid-template-areas:
      'filterTr'
      'main'
      'join';
      gap: 20px;

      &_join {
        margin-top: 20px;
        width: 640px;
        align-items: flex-start;

        &_img {
          background-image: url(../../images/advertise640.jpg);
          width: 640px;
        }

        &_linear {
          width: 640px;

        }

        &_box {
          left: 44.14px;
          width: 551.72px;

          &_button {
            width: 551.72px;
          }
        }
      }
      &_main {
        width: 640px;
      }
    }
  }
}

@media all and (max-width: 719px) {
  .Trainers_page {
    margin-top: 12px;
    margin-bottom: 40px;
    width: 296px;

    &_topic {

      &_head {
        height: 24px;
        font-size: 24px;
        line-height: 24px;
      }

      &_text {
        width: 296px;
        height: 80px;
        font-size: 14px;
        line-height: 20px;
      }
    }

    &_container {
      margin-top: 12px;

      &_join {
        width: 296px;

        &_img {
          width: 296px;
          background-image: url(../../images/advertise296.jpg);
        }

        &_linear {
          width: 296px;
        }

        &_box {
          left: 20.41px;
          width: 255.17px;

          &_button {
            width: 255.17px;
          }
        }
      }
      
      &_main {
        width: 296px;
        gap: 8px;
      }
    }
  }  
}