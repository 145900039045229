@font-face {
    font-family: 'Nunito Sans';
    /* src: url('NunitoSans-Bold.eot'); */
    src: local('Nunito Sans Bold'), local('NunitoSans-Bold'),
        /* url('NunitoSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('NunitoSans-Bold.woff2') format('woff2'),
        url('NunitoSans-Bold.woff') format('woff'), */
        url('NunitoSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    /* src: url('NunitoSans-Regular.eot'); */
    src: local('Nunito Sans Regular'), local('NunitoSans-Regular'),
        /* url('NunitoSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('NunitoSans-Regular.woff2') format('woff2'),
        url('NunitoSans-Regular.woff') format('woff'), */
        url('NunitoSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-SemiBold.eot');
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
        url('Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-SemiBold.woff2') format('woff2'),
        url('Montserrat-SemiBold.woff') format('woff'),
        url('Montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('Montserrat-Regular.eot');
    src: local('Montserrat Regular'), local('Montserrat-Regular'),
        url('Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
        url('Montserrat-Regular.woff2') format('woff2'),
        url('Montserrat-Regular.woff') format('woff'),
        url('Montserrat-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}